<template>
  <AuthLayout>
    <!-- <template #header>
      <img src="@/assets/images/careercolor.svg" alt="Logo" />
      lg:w-[753px]
    </template> -->

    <div class="p-6 md:p-8 bg-white rounded-xl my-9">
      <div>
        <h1 class="text-brand-black text-[32px] font-semibold leading-[130%] mb-1">Hi there! Lets get started 🎉</h1>
        <p class="text-[#8F8F8F] text-base font-normal">
          To create your employer account, kindly fill in the form below with the required information.
        </p>
      </div>

      <form @submit.prevent="goNext">
        <div class="pt-4 md:grid grid-cols-2 gap-4">
          <div>
            <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Fisrt Name <is-required /></label>
            <input
              type="text"
              id="first_name"
              class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
              placeholder="First Name"
              v-model="userDetails.first_name"
              minlength="3"
              required
            />
          </div>
          <div class="pt-4 md:pt-0">
            <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Last Name <is-required /></label>
            <input
              type="text"
              id="last_name"
              class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
              placeholder="Last Name"
              v-model="userDetails.last_name"
              minlength="3"
              required
            />
          </div>
        </div>

        <div class="pt-4">
          <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Company name <is-required /></label>
          <input
            type="text"
            id="company_name"
            placeholder="career buddy"
            class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
            v-model="userDetails.company_name"
            required
          />
        </div>
        <div class="pt-4">
          <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]"> Work Email <is-required /></label>
          <input
            type="email"
            id="email"
            placeholder="example: careerbuddy.com"
            class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
            v-model="userDetails.email"
            required
          />
        </div>

        <div class="pt-4 md:grid grid-cols-2 gap-4">
          <div>
            <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]"> Enter Password <is-required /></label>
            <div class="relative">
              <input
                :type="showPassword ? 'text' : 'password'"
                placeholder="****************"
                id="password-icon"
                class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full pl-4 pr-10 p-3 focus:outline-none outline-none"
                v-model="userDetails.password"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
                <EyeSlashSvg v-if="!showPassword" @click="showPassword = true" />
                <TimesSvg v-else @click="showPassword = false" />
              </div>
            </div>
          </div>
          <div class="pt-4 md:pt-0">
            <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Confirm Password <is-required /></label>
            <div class="relative">
              <input
                :type="showPassword ? 'text' : 'password'"
                placeholder="****************"
                id="password-icon"
                class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full pl-4 pr-10 p-3 focus:outline-none outline-none"
                v-model="userDetails.password_confirmation"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
                <EyeSlashSvg v-if="!showPassword" @click="showPassword = true" />
                <TimesSvg v-else @click="showPassword = false" />
              </div>
            </div>
          </div>
        </div>

        <div class="pt-[1.7rem] pb-2">
          <p class="text-[14px] text-brand-black leading-[140%]">
            <a class="f text[#090E11] cursor-pointer" :href="terms_link"
              >By clicking Sign up you agree to Careerbuddy <span class="underline">Terms of Service</span> and
              <span class="underline">Privacy Policies</span></a
            >
          </p>
        </div>

        <div class="pt-4">
          <button
            type="submit"
            :disabled="checkDisability"
            class="w-full bg-brand-black text-white p-3 px-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
          >
            {{ loading ? 'Loading...' : 'Sign up' }}
            <btn-loader :loading="loading" />
          </button>
        </div>
      </form>
      <div class="text-center mt-6">
        <span class="font-normal text-light-gray text-base text-center"
          >Already have an employer account? <router-link to="/login" class="text-brand-black font-medium">Log in here</router-link></span
        >
      </div>
    </div>
  </AuthLayout>
</template>

<script setup>
import AuthLayout from '@/layouts/AuthLayout.vue';
import EyeSlashSvg from '@/assets/icons/eye-slash-outline.svg?inline';
import TimesSvg from '@/assets/icons/times.svg?inline';
import IsRequired from '@/components/IsRequired.vue';
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';

const router = useRouter();
const store = useStore();
const props = defineProps({
  registrationType: String,
});
const showPassword = ref(false);
const userDetails = ref({
  first_name: '',
  last_name: '',
  email: '',
  company_name: '',
  password: '',
  password_confirmation: '',
  role_at_company: 'admin', //To be removed
  registration_type: 'employer',
  employer_registration_type: 'company', //to be removed
  auth_type: 'email',
});
const terms_link = computed(() => `//${process.env.VUE_APP_DOMAIN}/terms-and-privacy`);
// const job_roles = computed(() => store.getters['global/getCompanyRoles']);
const disabled = ref(true);
const loading = ref(false);

// if (!['company', 'individual'].includes(props.registrationType)) {
//   router.push('/login');
// }
const checkDisability = computed(() => {
  return (userDetails.value.first_name && userDetails.value.last_name && userDetails.value.email !== '') || userDetails.value.company_name !== ''
    ? (disabled.value = false)
    : (disabled.value = true);
});
const goNext = async () => {
  try {
    disabled.value = true;
    loading.value = true;
    localStorage.setItem('details', JSON.stringify(userDetails.value));
    await store.commit('global/setForm', { key: 'email', value: userDetails.email });
    await store.dispatch('auth/register', userDetails.value);
    router.push('/register-verify');
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    disabled.value = false;
    loading.value = false;
  }
};

const persistedInputField = () => {
  const formData = JSON.parse(localStorage.getItem('details'));
  if (formData) {
    userDetails.value = formData;
  }
};

onMounted(() => {
  if (!Array.isArray(store.getters['global/getCompanyRoles'])) {
    store.dispatch('global/getEnums');
  }
  if (!Array.isArray(store.getters['global/getCountries'])) {
    store.dispatch('global/getCountries');
  } else if (store.getters['global/getCountries'].length < 1) {
    store.dispatch('global/getCountries');
  }
  persistedInputField();
});
</script>

<style></style>
